<template>
  <div class="group/layout layout-wide relative flex flex-1 flex-col px-2 pb-3 sm:px-4 lg:px-3">
    <SiteHeader v-if="!isHome" />
    <main class="flex flex-1 flex-col">
      <slot />
    </main>
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const isHome = computed(() => route.name == "index")
</script>

<style scoped>
</style>
